import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'AddEditRuleMasterDetails',
  props: ['dunningMasterRowDetail'],
  data() {
    return {
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      editMode: false,
      showAddLetterMasterModal: false,
      ruleId: 0,
      ruleTypeName: {
        text: null,
        value: null
      },
      mapLetter: {
        text: null,
        value: null
      },
      executionDaysFrom: null,
      executionDaysTo: null,
      ruleName: null,
      outstandingTo: null,
      outstandingFrom: null,
      outstandingPercentage: null,
      daysBasis: {
        text: null,
        value: null
      },
      revenueAmountFrom: null,
      revenueAmountTo: null,
      graceInDays:null,
      preAlertDays:null,
      vsetCode: null,
      setTimeVsetCode: null,
      active: false,
      parent_value_set_id: null,
      showValueSetModal: false,
      showAddDunningMasterModal: false,
      payload: null,
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      loader: false,
      gstrTwoASixADetails: null,
      ruleMasterId: 0,
      defaultValue:{
        value: null,
        text: null
      }
    };
  },
  validations: {
    ruleTypeName: {
      value: { required }
    },
    daysBasis: {
      value: { required }
    },
    mapLetter: {
      value: { required }
    },
    ruleName: { required }
  },
  mounted() {
    if (this.dunningMasterRowDetail) {
      this.ruleId = this.dunningMasterRowDetail.rule_id;
      this.getDunningRuleMasterDetailsDataById();
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addUpdateRuleMasterDetailsData();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getDunningRuleMasterDetailsDataById() {
      this.loader = true;
      this.$store
        .dispatch(
          'dunningAdmin/getDunningRuleMasterDetailsDataById',
          this.dunningMasterRowDetail.rule_id
        )
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.ruleTypeName.text = result.rule_master_name;
            this.ruleTypeName.value = result.rule_master_id;
            this.mapLetter.text = result.letter_format_type;
            this.mapLetter.value = result.letter_format_type_id;
            this.daysBasis.text = result.execution_basis;
            this.daysBasis.value = result.execution_basis;
            this.ruleName = result.rule_name;
            this.executionDaysFrom = result.execution_days_from;
            this.executionDaysTo = result.execution_days_to;
            this.outstandingFrom = result.outstanding_from;
            this.outstandingTo = result.outstanding_to;
            this.outstandingPercentage = result.outstanding_percentage;
            this.revenueAmountFrom = result.revenue_amount_from;
            this.revenueAmountTo = result.revenue_amount_to;
            this.graceInDays = result.grace_days;
            this.preAlertDays = result.pre_alert_days;
            this.active = result.is_activated === 'Y' ? true : false;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addUpdateRuleMasterDetailsData() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          rule_id: this.ruleId,
          rule_master_id: this.ruleTypeName.value,
          rule_name: this.ruleName,
          rule_master_name: this.ruleTypeName.text,
          letter_format_type: this.mapLetter.text,
          letter_format_type_id: this.mapLetter.value,
          execution_basis: this.daysBasis.text,
          execution_days_from: this.executionDaysFrom,
          execution_days_to: this.executionDaysTo,
          outstanding_from: this.outstandingFrom,
          outstanding_to: this.outstandingTo,
          outstanding_percentage: this.outstandingPercentage,
          revenue_amount_from: this.revenueAmountFrom,
          revenue_amount_to: this.revenueAmountTo,
          grace_days:this.graceInDays,
          pre_alert_days:this.preAlertDays,
          is_activated: this.active ? 'Y' : 'N'
        };
        this.loader = true;
        this.$store
          .dispatch('dunningAdmin/addUpdateRuleMasterDetailsData', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 201) {
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.DUNNING_RULE_MASTER_LIST) {
        this.ruleTypeName = {
          value: item.value_set_dtl_id,
          text: item.value_code
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.DUNNING_LETTER_RULE_FORMAT) {
        this.mapLetter = {
          text: item.value_code,
          value: item.value_set_dtl_id
          
        }
        // this.mapLetter.text = item.value_code;
        // this.mapLetter.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.DUNNING_RULE_DAYS_BASIS) {
        this.daysBasis = {
          value: item.value_meaning,
          text: item.value_code
        }
        // this.daysBasis.text = item.value_meaning;
        // this.daysBasis.value = item.value_code;
      }
      this.showValueSetModal = false;
    },

    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode){
      switch(vsetCode) {
        case appStrings.VALUESETTYPE.DUNNING_RULE_MASTER_LIST:
          this.ruleTypeName = this.defaultValue
          break;
        case appStrings.VALUESETTYPE.DUNNING_LETTER_RULE_FORMAT:
          this.mapLetter = this.defaultValue
          break;
        case appStrings.VALUESETTYPE.DUNNING_RULE_DAYS_BASIS:
          this.daysBasis = this.defaultValue
          break;
    }
    }
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.unsubscribe();
  }
  };
