import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import addEditRuleMasterDetails from './addEditRuleMasterDetails';

export default {
  name: 'DunningRuleMasterDetails',
  watch: {
    currentPage: function() {
      this.getDunningRuleMasterDetailsList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getDunningRuleMasterDetailsList();
    }
  },
  components: {
    addEditRuleMasterDetails
  },
  data() {
    return {
      ruleMastersId: null,
      dunningMasterDetailsId: null,
      letterTypeId: null,
      dunningMasterRowDetail: null,
      showAddRuleMasterDetailsModal: false,
      active: true,
      ruleId: null,
      ruleMasterName: {
        value: null,
        text: null
      },
      mapLetter: {
        value: null,
        text: null
      },
      graceInDays: null,
      preAlertDays:null,
      ruleName: null,
      executionDays: null,
      outstandingFrom: null,
      outstandingTo: null,
      daysBasis: {
        text: null,
        value: null
      },
      outstandingPercentage: null,
      revenueAmountFrom: null,
      revenueAmountTo: null,

      parent_value_set_id: null,
      showValueSetModal: false,
      params: null,
      dtl_id: null,
      requestParams: {},
      searchParams: {},
      payload: null,
      showChildModal: false,
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      showModal: false,
      tab: null,
      gstrTwoASixADetails: null,

      showAdvSearchModal: false,
      vsetCode: null,
      setTimeVsetCode: null,
      legalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      periodType: null,
      OperatingUnitList: [
        {
          value: '2A',
          text: '2A'
        },
        {
          value: '2B',
          text: '2B'
        }
      ],
      dunningRuleMasterDetailsData: [],
      defaultValue:{
        value: null,
        text: null
      },
      dunningRuleMasterDetailsFields: [
        {
          key: 'rule_master_name'
        },
        {
          key: 'rule_name'
        },
        {
          key: 'execution_days_from'
        },
        {
          key: 'execution_days_to'
        },
        {
          key: 'outstanding_from'
        },
        {
          key: 'outstanding_to'
        },
        {
          key: 'outstanding_percentage'
        },
        {
          key: 'execution_basis',
          label: 'No Of Days Basis'
        },
        {
          key: 'revenue_amount_from'
        },
        {
          key: 'revenue_amount_to'
        },
        {
          key: 'letter_format_type',
          label: 'Map Letter'
        },
        {
          key: 'grace_days',
          label:'Grace In Days'
        },
        {
          key: 'pre_alert_days'
        },
        {
          key: 'is_activated',
          label: 'Active'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showAddRuleMasterDetailsModal = true;
          this.dunningMasterRowDetail = null;
          this.dunningMasterDetailsId = null;
          this.letterTypeId = null;
        }
      }
    });
  },
  methods: {
    getDunningRuleMasterDetailsList() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        rule_master_id: this.ruleMasterName.value,
        letter_format_type: this.mapLetter.value,
        rule_name: this.ruleName,
        execution_days: this.executionDays,
        outstanding_from: this.outstandingFrom,
        outstanding_to: this.outstandingTo,
        outstanding_percentage: this.outstandingPercentage,
        execution_basis: this.daysBasis.value,
        revenue_amount_from: this.revenueAmountFrom,
        revenue_amount_to: this.revenueAmountTo,
        is_activated: this.active ? 'Y' : 'N'
      };
      this.loader = true;
      this.$store
        .dispatch('dunningAdmin/getDunningRuleMasterDetailsList', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.dunningRuleMasterDetailsData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.DUNNING_RULE_MASTER_LIST) {
        this.ruleMasterName = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.DUNNING_LETTER_RULE_FORMAT
      ) {
        this.mapLetter.text = item.value_code;
        this.mapLetter.value = item.value_set_dtl_id;
      }
      if (this.vsetCode === appStrings.VALUESETTYPE.DUNNING_RULE_DAYS_BASIS) {
        this.daysBasis = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
      this.showValueSetModal = false;
    },

    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },

    closeValueSetModal() {
      this.showValueSetModal = false;
    },

    clear() {
      this.ruleId = null;
      this.ruleMasterName = {
        value: null,
        text: null
      };
      this.ruleName = null;
      this.executionDays = null;
      this.outstandingFrom = null;
      this.outstandingTo = null;
      this.daysBasis = {
        text: null,
        value: null
      };
      this.outstandingPercentage = null;
      this.revenueAmountFrom = null;
      this.revenueAmountTo = null;
      this.mapLetter = {
        value: null,
        text: null
      };
      this.totalRows = null;
      this.graceInDays = null;
      this.preAlertDays = null;
      this.setupType = null;
      this.setupSubType = null;
      this.setupValue = null;
      this.dunningRuleMasterDetailsData = [];
      this.parent_value_set_id = null;
    },
    rowSelected(item) {
      this.dunningMasterRowDetail = item;
      this.showHideAddRuleMasterDetailsModal(true);
    },
    showHideAddRuleMasterDetailsModal(flag) {
      this.showAddRuleMasterDetailsModal = flag;
      if (!flag) {
        this.getDunningRuleMasterDetailsList();
      }
    },
    beforeDestroy() {
      clearTimeout(this.setTimeVsetCode);
      this.unsubscribe();
    },
    clearVsetValues(vsetCode) {
      switch(vsetCode){
        case appStrings.VALUESETTYPE.DUNNING_RULE_MASTER_LIST:
          this.ruleMasterName = this.defaultValue
          break;
        case appStrings.VALUESETTYPE.DUNNING_LETTER_RULE_FORMAT:
          this.mapLetter = this.defaultValue
          break;
        case appStrings.VALUESETTYPE.DUNNING_RULE_DAYS_BASIS:
          this.daysBasis = this.defaultValue
          break;
        }
      }
    }  
};
